$index: calc(1vw + 1vh);
$gap: calc(var($index) * 10);


// colors
$base: #1A2543;
$white: #fff;
$primary: #CF6195;


.btn {
    position: relative;
    height: 3.25rem;
    padding: 0 1.5rem;
    border: 1px solid transparent;
    background-color: $primary;
    color: $white;
    font-size: 0.785rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 999rem;
    pointer-events: all;
    overflow: hidden;
    transition: all .3s ease-in-out;
    cursor: pointer;
}