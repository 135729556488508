.modal {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5vh 0;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all .3s;
    pointer-events: none;
    z-index: 1050;

    &--active {
        visibility: visible;
        opacity: 1;
        pointer-events: inherit;
    }

    &__window { 
        position: relative;
        background-color: $white;
        width: 100%;
        z-index: 1060;
        max-height: 100vh;
    }

    &__window-close {
        position: absolute;
        top: 0;
        right: 0;
        transition: all .3s ease-in-out;

        & .btn-close {
            width: 2rem;
            height: 2rem;
            font-size: 1.25rem;
            cursor: pointer;
        }

        &:hover {
            transform: rotate(180deg);
        }
    }

    &__window-img {
        width: 100%;
        height: 100%;
        background-image: url(../img/modal.webp);
        background-size: cover;
        background-position: bottom center;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__window-content {
        padding: 2rem 4rem;
        
        @include media-breakpoint-down(sm) {
            padding: 1rem;
        }
    }

    &__window-heading {
        margin-bottom: 2rem;

        & .h3 {
            @include media-breakpoint-down(md) {
                font-size: 2rem;
            }
        }
    }
}