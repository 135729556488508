.products {
    
    &__content {
        overflow: hidden;
        display: flex;
        align-items: center;
        left: 0;

        @include media-breakpoint-down(lg) {
            display: block;
            padding: 2rem 2.25rem;
        }

        @include media-breakpoint-down(md) {
            padding: 2rem 0.75rem;
        }
    }

    &__content-title {
        width: min-content;
        padding: 0 10vw;

        @include media-breakpoint-down(lg) {
            width: 100%;
            padding: 0;
        }
    }

    &__item {
        width: 60vw;
        padding: 10vw 5vw;

        @include media-breakpoint-down(lg) {
            width: 100%;
            padding: 1rem 0;
        }
    }
}
