.form {

    &__message {
        display: none;

        &--show {
            display: block;
        }
        
        & span {
            display: block;
            font-size: 1.125rem;
            font-weight: 700;
        }

        &--success {
            display: none;
            margin-top: 1.5rem;

            &-show {
                display: block;
            }
            
            & span {
                color: green;
            }
        }

        &--error {
            display: none;
            
            &-show {
                display: block;
            }

            & span {
                color: red;
            }
        }
    }
}