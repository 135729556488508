.contacts {

    &__address {

        &--list {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        &--title {
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
        }
    }

    &__btn {
        align-self: flex-start;
    }

    &__team {

        &--list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 3rem;

            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
            }
        }

        &--name {
            font-size: 1.5rem;
            font-weight: 600;
        }

        &--person {
            
        }

        &--email {
            color: $primary;
        }
    }
}