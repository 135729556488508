.offcanvas {
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all .3s;
    pointer-events: none;
    z-index: 1000;

    &--active {
        opacity: 1;
        transition: all .3s;
        pointer-events: inherit;

        & .offcanvas__content {
            transform: translateX(0);
            transition: all .4s ease-in-out;
        }
    }

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        background-color: $white;
        padding: 0 4rem;
        width: 30vw;
        height: 100vh;
        transform: translateX(-100%);
        transition: all .4s ease-in-out;

        @include media-breakpoint-down(lg) {
            width: 50vw;
        }

        @include media-breakpoint-down(sm) {
            width: 100vw;
        }
        
    }

    &__menu {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__menu-item {
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {
            color: lighten($base, 50%);
        }
    }
}