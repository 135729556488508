* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
}

img {
    width: 100%;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    outline: none;
  }
  
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6 {
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
  }
  
  h1, .h1 {
    font-size: 3rem;

    @include media-breakpoint-down(xl) {
      font-size: 2.75rem;
    }
  }
  
  h2, .h2 {
    font-size: 2.75rem;

    @include media-breakpoint-down(xl) {
      font-size: 2rem;
    }
  }
  
  h3, .h3 {
    font-size: 2rem;
  }
  
  h4, .h4 {
    font-size: 1.25rem;
  }
  
  a {
    color: inherit;
    text-decoration: unset;
  }

  .section {
    padding: 5rem 0;

    @include media-breakpoint-down(lg) {
      padding: 3rem 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
    }

    &__heading {
      margin-bottom: 1.5rem;

      &--ondark {
        color: $white;
      }
    }
  }