.distribution {

    & p {
        margin-bottom: 1.5rem;
    }

    &__logo-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;
    }

    &__logo-item {
        & img {
            filter: grayscale(1);
            transition: all .2s ease-in-out;

            &:hover {
                filter: grayscale(0);
            }
        }
    }
}