.about {
    position: relative;
    width: 100%;
    overflow: hidden;

    & p {
        margin-bottom: 2rem;
    }

    &__img-wrap {
        position: relative;
    }

    &__text-circle {
        position: absolute;
        top: 0;
        right: -10%;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        text-align: center;
        line-height: 200px;
        font-size: 20px;
        animation: rotating linear 12s infinite;

        @include media-breakpoint-down(md) {
            top: 50%;
            right: 50%;
            width: 100px;
            height: 100px;
            line-height: 100px;
        }

        & span {
            display: inline-block;
            width: 25px;
            position: absolute;
            transform: translate(-12.5px,0px)rotate(var(--rot)) translate(0,-105px)
        }

        @keyframes rotating {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
        }
    }
}