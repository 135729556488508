.map {
  position: relative;
  background-color: $base;
  height: 800px;

  @include media-breakpoint-down(lg) {
    height: auto;
    padding: 2rem 0;
  }

  &__info {
    position: absolute;
    display: flex;
    align-items: center;
    width: 50%;
    top: 0;
    bottom: 0;
    z-index: 2;

    @include media-breakpoint-down(lg) {
      width: 100%;
      position: relative;
      display: block;
    }
  }

  &__headind {
    width: min-content;
    font-size: 3.25rem;
    font-weight: 700;
    color: $white;

    & span {
      display: block;
      width: max-content;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    max-height: 100%;
    overflow: hidden;
    z-index: 1;

    & #map-bg-animation {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  &__wrapper {
    position: relative;
    width: 100vw;
    transform: translateY(-20%);
    top: -50%;
    right: -50%;

    @include media-breakpoint-down(lg) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

    // & .svg {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    // }

    &__path {
      fill: #5720bb;
      fill-opacity: 1;
      stroke: #6a2ddb;
      stroke-opacity: 1;
      stroke-width: 0.5;

      &:hover {
        fill: #6c30db;
        stroke: #7d3ff1;
      }
    }

    &__point {
      cursor: pointer;
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: lighten($primary, 50%);
      border-radius: 50%;
      transition: all 0.3s ease;
      will-change: transform, box-shadow;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 0 rgba(255,255,255, 0.4);
      animation: pulse 3s infinite;

      @include media-breakpoint-down(sm) {
        width: 10px;
      height: 10px;
      }

      &:hover {
        animation: none;
        transform: translate(-50%, -50%) scale3D(1.35, 1.35, 1);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }

      &--moscow { 
        top: 77%; 
        left: 11%; 
      }

      &--piterburg { 
        top: 70%; 
        left: 7%; 
      }

      &--murmansk { 
        top: 52%; 
        left: 7%; 
      }

      &--arhangelsk { 
        top: 63%; 
        left: 13%; 
      }
      
      &--yaroslavl { 
        top: 73%; 
        left: 11%; 
      }

      &--belgorod { 
        top: 84%; 
        left: 10%; 
      }

      &--donetsk { 
        top: 86%; 
        left: 8%; 
      }

      &--lugansk { 
        top: 87%; 
        left: 10%; 
      }

      &--sevastopol { 
        top: 87%; 
        left: 10%; 
      }

      &--rostov { 
        top: 88%; 
        left: 12%; 
      }

      &--krasnodar { 
        top: 91%; 
        left: 11%; 
      }

      &--samara { 
        top: 81%; 
        left: 18%; 
      }

      &--ufa { 
        top: 79%; 
        left: 21%; 
      }

      &--ekb { 
        top: 72%; 
        left: 25%; 
      }

      &--novosibirsk { 
        top: 78%; 
        left: 35%; 
      }
    }

    
}

  /**New css for tooltip**/
  .tooltiptext {
    position: fixed;
    // display: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border:#d3d3d3 solid 1px;
    background: #fff;
    font-size: 10px;
    padding: 8px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
  }
  .tooltiptext.active {
    display: initial;
  }
 
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 208, 0.8);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(255, 0, 208, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 208, 0);
    }
  }
    