.hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../img/hero-gb.webp);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;

    @include media-breakpoint-down(md) {
        background-image: url(../img/hero-gb-sm.webp);
    }

    &__content {
        text-align: center;
        

        & h1 {
            color: $white;
            font-size: 2.25rem;
            filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.25));
        }
    }

    &__logo {
        width: 600px;
        filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.25));
        margin-bottom: 1rem;

        @include media-breakpoint-down(xxl) {
            margin-bottom: 0;
        }

        @include media-breakpoint-down(xl) {
            width: 100%;
            padding: 0 2rem;
        }

        @include media-breakpoint-down(sm) {
            width: 80%;
            padding: 0 2rem;
        }
    }
    

    &__leaf-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__leaf {
        position: absolute;

        &_1 {
            width: 80px;
            top: 15%;
            left: 25%;
        }
        &_2 {
            width: 60px;
            top: 10%;
            right: 40%;
        }
        &_3 {
            width: 60px;
            top: 20%;
            right: 20%;
        }
        &_4 {
            width: 60px;
            top: 70%;
            right: 15%;
        }
        &_5 {
            width: 60px;
            top: 80%;
            left: 15%;
        }
    }
}

