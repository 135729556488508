.burger {
    position: relative;
    cursor: pointer;


    & .line1,
    & .line2,
    & .line3 {
        width: 36px;
        height: 3px;
        background: $white;
        margin: 7px auto;
        transition-duration: 0.2s;
    }

    &_on {
        .line1 {
            transform: translateY(10px) rotate(135deg);
            background: $base;
        }

        .line2 {
            opacity: 0;
        }

        .line3 {
            transform: translateY(-10px) rotate(-135deg);
            background: $base;
        }
    }
}