.header {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    z-index: 1024;

   
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 4rem;

        @include media-breakpoint-down(md) {
            padding: 2rem 1rem;
        }
    }

    &__col {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    &__logo-wrap {
        position: relative;
    }

    &__logo {
        position: absolute;
        right: 0;
        left: 0;
        width: 5.875rem;
        top: -24px;
    }

    &__email {
        display: block;
        font-size: 0.785rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $white;
    }

    &__menu {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        @include media-breakpoint-down(xl) {
            display: none;
        }
    }

    &__menu-item {
        color: $white;
        font-size: 0.785rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}